$menuBreakpoint: $tablet;

.Header {
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  z-index: 120;

  body.--scrolled:not(.showMenu) &{
    pointer-events: none;

    > .wrapper{
      transform: translate(0, -100%);
    }
  }

  &.--visible{
    body.--scrolled &{
      pointer-events: all;

      > .wrapper{
        transform: translate(0, 0);
      }
    }

    body.--scrolled:not(.showMenu) &{
      > .wrapper{
        background: var(--white);
      }
    }
  }

  > .wrapper {
    @include flex();
    position: relative;
    z-index: 1;
    transition: transform 550ms var(--ease-in-out-quart),
                background 550ms var(--ease-in-out-quart);
  }

  .Brand {
    position: relative;
    z-index: 10;
    pointer-events: all;
    align-self: center;
  
    padding-left: calc(100vw / 24);

    svg > * {
      body.showMenu & {
        @include responsive($menuBreakpoint, max) {
          fill: $white;
        }
      }
    }
  }

  .Nav__languages,
  .Nav__toggle,
  .Nav__contact{
    position: relative;
    z-index: 10;
  }

  .Nav__languages{
    @include flex(center, center);
    margin-left: auto;
    margin-right: 30px;

    @include responsive($tabletSmall){
      margin-right: get-vw(30);
    }
    
    > .Link{
      font: 500 14px var(--main-font-text);
      text-transform: uppercase;

      &:before,
      &:after{
        content: none;
      }

      @include responsive($tabletSmall){
        @include font-vw(14);
      }
    }
  }

  .Nav__contact{
    @include flex(center, center);
    font: 500 14px var(--main-font-text);

    @include responsive($tabletSmall){
      @include font-vw(14);
    }

    @include noTouchHover() {
      > .Icon[data-name="message"]{
        animation: vibration 380ms var(--ease-in-out-quart);
      }
    }

    > span{
      @include responsive($tabletSmall, max){
        display: none;
      }

      @include responsive($tabletSmall){
        margin-left: 0.7em;
      }
    }
  }
}

@keyframes vibration {
  10%{ transform: rotate(-10deg) }
  50%{ transform: rotate(10deg) }
  90%{ transform: rotate(-10deg) }
}