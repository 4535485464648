.TechnicalLogistic{
  .visual__container{
    @include imgRatio(1200, 530, var(--black));
    margin-top: -15%;
    
    @include responsive($tabletSmall, max){
      margin-bottom: 2em;
    }

    @include responsive($tabletSmall){
      margin-bottom: get-vw(60);
      max-width: space(15);
      margin-left: auto;
      margin-right: auto;
    }


    & + .wrapper{
      @include responsive($tabletSmall){
        padding: 0 1em;
      }
    }
  }

  .wrapper{
    h2{
      text-align: left;

      @include responsive($tabletSmall){
        margin-bottom: get-vw(40);
      }
    }

    .wswyg--content{
      @include responsive($tabletSmall, max){
        margin-top: 2em;
      }

      @include responsive($tabletSmall){
        columns: 2;
        column-gap: get-vw(60);
      }
    }
  }
}