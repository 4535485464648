.Nav__toggle {
  // all: unset;
  // margin-left: auto;
  cursor: pointer;
  background: var(--grey-l);
  overflow: hidden;
  min-height: 75px;
  min-width: 75px;
  height: get-vw(105);
  width: get-vw(105);

  &, > .lines{
    @include flex(center, center);
  }

  > .ov{
    @include cover();
    clip-path: inset(0% 0% 100% 0%);
    background: var(--dark-l);
    transform: scale(1.2);
  }

  > .lines{
    @include cover();

    &:before,
    &:after{
      @include content();
      position: absolute;
      height: 1px;
      min-width: 40px;
      width: get-vw(40);
      background: var(--white);
      mix-blend-mode: difference;
      transition: transform 450ms var(--ease-in-out-quart);
    }

    &:before{ 
      transform: translate(0, -0.25em);

      body.showMenu &{
        transform: translate(0, 0) rotate(45deg);
      }
    }

    &:after{ 
      transform: translate(0, 0.25em);

      body.showMenu &{
        transform: translate(0, 0) rotate(-45deg);
      }
    }
  }
}
