.PageHeaderHome{
  .subtitle{
    filter: opacity(0.5);
  }

  h1, h2{
    z-index: 1;

    @include responsive($tabletSmall){
      @include font-vw(120);
    }

    strong{
      display: inline-block;
      color: var(--main-color);
      height: 0.5em;
    }
  }

  .subtitle{
    font: 500 24px housetelly-signature;
    text-transform: lowercase;
    filter: opacity(0.5);

    @include responsive($tabletSmall){
      @include font-vw(34);
    }
  }

  h1{
    margin-top: 0;
  }

  .visual__container{
    @include imgRatio(720, 810, var(--black));
    z-index: 0;
    margin: 0 auto;
    
    @include responsive($tabletSmall, max){
      max-width: 80%;
    }

    @include responsive($tabletSmall){
      max-width: space(12);
    }
  }

  .ScrollIndicator{
    @include flex(center, center);
    position: absolute;
    top: 90vh; right: 5%;
    height: get-vw(64);
    width: get-vw(64);
    border-radius: 50%;
    background: var(--grey-l);
    cursor: pointer;
    z-index: 10;
    
    @include responsive($tabletSmall, max){
      display: none;
    }

    .Icon[data-name="chevron"]{
      min-width: 15px;
      animation: scroll 2000ms linear infinite;
    }
  }
}

@keyframes scroll {
  0%{
    transform: translate(0, 0);
  }

  51%{
    transform: translate(0, 100%);
  }

  100%{
    transform: translate(0, 0);
  }
}