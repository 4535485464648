.Footer {
   color: var(--white);
   position: relative;
   z-index: 0;

   > .inner{
      background: var(--black);
   }
}

// Head of the Footer
.FooterHead{
   @include responsive($tabletSmall, max){
     padding: 10em 0 5em;
   }

   @include responsive($tabletSmall){
      padding: get-vw(200) 0 get-vw(50);
   }

   .wrapper{
      @include flex(flex-start);
      width: 100%;

      @include responsive($tabletSmall, max){
        flex-direction: column;
      }
   }

   .head,
   .main{
      width: 100%;
   }

   .head{
      @include responsive($tabletSmall, max){
        margin-bottom: 2em;
      }

      @include responsive($tabletSmall){
        max-width: space(2);
      }

      .Logo{
         @include responsive($tabletSmall, max){
           max-width: get-vw(300);
         }
      }

      .Socials{
         margin-top: 2em;
      }
   }

   .main{
      @include responsive($tabletSmall){
         max-width: space(10);
        margin-left: auto;
      }

      nav{
         @include responsive($tabletSmall, max){
            margin-bottom: 3em;
         }

         @include responsive($tabletSmall){
           margin-bottom: get-vw(90);
         }

         > ul{
            @include flex(flex-start);

            @include responsive($tabletSmall, max){
               flex-direction: column;
               gap: 1em;
            }

            @include responsive($tabletSmall){
              gap: get-vw(40);
            }
         }
      }

      .Link{
         font: 400 14px var(--current-font-text);

         @include responsive($tabletSmall){
           @include font-vw(14);
         }
      }
   }

   .recruitment{
      @include responsive($tabletSmall, max){
        margin-bottom: 3em;
      }

      h2{
         text-align: left;

         @include responsive($tabletSmall, max){
           margin-bottom: 1em;
         }

         @include responsive($tabletSmall){
           @include font-vw(65);
           margin-bottom: get-vw(30);
         }
      }
   }

   .Agencies{
      @include responsive($tabletSmall, max){
        margin-top: 2em;
      }

      @include responsive($tabletSmall){
        margin-top: get-vw(100);
      }

      .iso{
        align-items: center;
        justify-content: center;
      }
   }
}

.FooterBottom{
   font: 400 12px var(--main-font-text);

   @include responsive($tabletSmall, max){
     padding: 5em 0;
   }

   @include responsive($tabletSmall){
     @include font-vw(12);
     padding: get-vw(50) 0;
   }

   .Link{ font: inherit }

   > .container{
      @include flex(center);

      @include responsive($tabletSmall, max){
        flex-direction: column;
        gap: 2em;
      }

      @include responsive($tabletSmall){
        justify-content: space-between;
      }
   }

   .items{
      @include flex();
      filter: opacity(0.3);

      @include responsive($tabletSmall, max){
         gap: 1em;
      }

      @include responsive($tabletSmall){
         gap: get-vw(25);
      }
   }

   .pa-group{
      @include flex(center, center);
      flex-direction: column;
      transition: transform 500ms var(--ease-in-out-circ);

      @include noTouchHover() {
         transform: scale(0.9);
      }

      > span{
         font: 400 13px var(--main-font-text);
         text-transform: uppercase;
         filter: opacity(0.3);
         margin-bottom: 0.5em;

         @include responsive($tabletSmall){
           @include font-vw(13);
         }
      }

      .visual__container{
         @include imgRatio(162, 15);
         width: get-vw(162);
         min-width: 150px;
      }
   }
}
