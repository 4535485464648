.QuoteFull{
  > .container{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    @include responsive($tabletSmall){
      justify-content: center;
    }
  }

  .v-container{
    @include imgRatio(540, 640);
    width: 100%;

    @include responsive($tabletSmall, max){
      max-width: 50%;
      margin: 0 auto;
    }
    
    @include responsive($tabletSmall){
      max-width: space(7);
    }

    .visual__container{
      @include cover();
    }

    .Icon{
      position: absolute;
      top: 0; right: 0;
      transform: translate(30%, 10%);
    }
  }

  .wrapper{
    @include responsive($tabletSmall, max){
      margin-top: 2em;
    }

    @include responsive($tabletSmall){
      max-width: space(7);
      margin-left: 15%;
      margin-right: space(1);
    }
  }

  figcaption{
    color: var(--text-color);

    @include responsive($tabletSmall, max){
      margin-top: 1.5em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(25);
    }
  }

  .wswyg--content{
    *{
      @include responsive($tabletSmall, max){
        text-align: center;
      }
    }
  }
}