.Experts{
  padding: 0;

  > .container{
    padding: get-vw(150) 0;

    @include responsive($tabletSmall) {
      padding: get-vw(150) 0;
    }
  }

  // Wrapper for content
  .wrapper{
    @include flex(center, center);
    flex-direction: column;
    z-index: 1;
    padding: 1em;
    background: rgba(247, 247, 247, 0.2);
    backdrop-filter: blur(5px);


    @include responsive($tabletSmall){
      max-width: space(10);
      margin: 0 auto;
    }

    > .wswyg--content *{
      text-align: center;
      margin: 0;
    }

    > .wswyg--content,
    > .Btn{
      @include responsive($tabletSmall, max){
        margin-top: 2em;
      }

      @include responsive($tabletSmall){
        margin-top: get-vw(40);
      }
    }

    > .wswyg--content{
      @include flex(center, center);
      flex-direction: column;
    }
  }

  // Gallery container for portrait bubbles
  .gallery-container {
    @include cover();

    // Pictures container
    .gallery{
      @include flex(flex-start);
      flex-wrap: wrap;
      flex-direction: column;
    }

    // Visuals
    .visual__container {
      @include imgRatio(250, 250);
      width: get-vw(250);
      min-width: 200px;

      img {
        height: 100%;
        width: 100%; 
        object-fit: cover;
        clip-path: circle(40%);
      }

      &:nth-child(5n + 1){
        margin-left: 10%;
      }

      &:nth-child(5n + 2){
        margin-left: 70%;

        img{ transform: scale(0.7) }
      }

      &:nth-child(5n + 3){
        margin-left: 25%;

        img{ transform: scale(0.3) }
      }

      &:nth-child(5n + 4){
        margin-left: 80%;

        img{ transform: scale(0.9) }
      }

      &:nth-child(5n + 5){
        margin-left: 50%;

        img{ transform: scale(0.5) }
      }
    }
  }
}
