.PresentationWithPicture{
  &.--reverse{
    > .container{
      @include responsive($tabletSmall){
        flex-direction: row-reverse;
      }
    }

    .wrapper{
      @include responsive($tabletSmall){
        margin-left: auto;
      }
    }
  }

  &:not(.--reverse){
    .visual__container{
      @include responsive($tabletSmall){
        margin-left: auto;      
      }
    }
  }

  > .container{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    @include responsive($tabletSmall){
      align-items: center;
    }
  }

  .wrapper{
    @include flex(flex-start);
    flex-direction: column;
    position: relative;

    @include responsive($tabletSmall){
      max-width: space(9);
    }

    > *:not(.Icon){
      position: relative;
      z-index: 1;
    }

    h2{
      text-align: left;
    }

    .wswyg--content {
      @include responsive($tabletSmall, max){
        margin-top: 2em;
      }

      @include responsive($tabletSmall){
        margin-top: get-vw(40);
      }

      *{ color: var(--text-color) }
    }

    .Icon[data-name="star"] {
      position: absolute;
      top: 0; left: 0;
      transform: translate(-30%, -30%);
      min-width: 100px;
      width: get-vw(400);
      z-index: -1;

      svg > *{ fill: #f0f0f0 }
    }
  }

  .visual__container{
    @include imgRatio(490, 600, var(--black));

    @include responsive($tabletSmall, max){
      margin-top: 3em;
      max-width: 70%;
    }

    @include responsive($tabletSmall){
      max-width: space(7);
    }
  }
}
