.Copyright{
  gap: 0.25em;

  &, .wkn{
    @include flex(center, center);
  }

  .wkn{
    @include imgRatio(72, 9);
    margin-top: 0.1em;
    width: get-vw(60);
    position: relative;

    @include responsive($tabletSmall, max){
      min-width: 60px;
    }

    svg {
      @include cover();

      > *{
        fill: var(--white);
      }
    }
  }
}