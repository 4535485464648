.RealisationAccompaniment{
  .wrapper{
    @include flex();

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    > .head{
      @include responsive($tabletSmall, max){
        margin-bottom: 3em;
      }

      @include responsive($tabletSmall){
        max-width: space(10);
      }

      h2{
        @include responsive($tabletSmall){
          text-align: left;
        }

        &.--mid{
          @include responsive($tabletSmall){
            @include font-vw(50);
          }
        }
      }
    }
  }
  
  .swiper {
    min-width: 270px;
    min-height: 400px;

    @include responsive($tabletSmall, max){
      width: 80vw;
    }
    
    @include responsive($tabletSmall){
      width: get-vw(500);
      min-height: get-vw(400);
      margin-top: get-vw(-100);
      margin-left: auto;
      margin-right: 0;
    }

    &.swiper-3d .swiper-slide-shadow{
      background: none;
    }

    &-slide{
      background: var(--green-d8);

      &:nth-child(3n + 1){
        background: var(--grey-l);
      }
  
      &:nth-child(3n + 2){
        background: var(--grey-l-d1);
      }

      &:nth-child(3n + 3){
        background: var(--grey-l-d2);
      }

      .inner{
        @include flex();
        flex-direction: column;
        height: 100%;

        @include responsive($tabletSmall, max){
          padding: 1.2em;
        }
      
        @include responsive($tabletSmall){
          padding: 3em;
        }
      }

      .head{
        font: 700 60px var(--main-font-title);

        @include responsive($tabletSmall, max){
          padding-bottom: 0.5em;
        }

        @include responsive($tabletSmall){
          @include font-vw(90);
          padding-bottom: get-vw(30);
        }
      }

      .content{
        margin-top: auto;

        > span{
          @include responsive($tabletSmall){
            @include font-vw(28);
          }
        }

        > p{
          @include responsive($tabletSmall, max){
            margin-top: 1em;
          }

          @include responsive($tabletSmall){
            margin-top: get-vw(20);
          }
        }
      }
    }

    &-navigation{
      @include responsive($tabletSmall, max){
        margin-top: 1em;
        justify-content: center;
      }

      @include responsive($tabletSmall){
        margin-top: get-vw(45);
      }

      > *{
        background: var(--main-color);
      }
    }
  }
  
}