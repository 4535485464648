.TimelineStep{
  @include responsive($tabletSmall, max){
    padding: 5em 0;
  }

  @include responsive($tabletSmall){
    padding: get-vw(150) 0;  
  }

  &:nth-child(odd){
    h2{ text-align: left }

    .wswyg--content{
      @include responsive($tabletSmall){
        margin-left: 0;
      }
    }
  }

  &:nth-child(even){
    h2{
      @include responsive($tabletSmall){
        text-align: right
      }
    }

    .wswyg--content{
      @include responsive($tabletSmall){
        margin-left: auto;
        margin-right: space(3);
      }
    }
  }

  &[data-featured]{
    margin: 0 space(-1);

    .wrapper{
      @include responsive($tabletSmall, max){
        padding-left: 0;
      }
    }

    h2{
      color: var(--white);
      text-align: center;
    }

    .step{ 
      margin-top: -4.5em;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .wswyg--content{
      margin: 0 auto;

      @include responsive($tabletSmall, max){
        max-width: 90%;
      }

      @include responsive($tabletSmall){
        max-width: get-vw(450);
      }

      *{ 
        color: var(--grey-l-d1);

        @include responsive($tabletSmall, max){
          text-align: center;
        }
      }
    }
  }

  .wrapper{
    z-index: 1;

    @include responsive($tabletSmall, max){
      padding-left: 20%;
    }

    @include responsive($mobile, max){
      padding-left: 30%;
    }
  }

  h2{
    @include responsive($tabletSmall, max){
      font-size: 30px;
      text-align: left;
    }
  }

  .wswyg--content{
    @include responsive($tabletSmall){
      max-width: space(6);
      margin-top: get-vw(40);
    }

    *{ color: var(--text-color) }
  }

  .step{
    position: absolute;
    top: 0;
    background: var(--main-color);

    @include responsive($tabletSmall, max){
      left: -30%;
      padding: 1em 1.5em;
    }

    @include responsive($mobile, max){
      left: -50%;
    }
    
    @include responsive($tabletSmall){
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .visual__container{
    z-index: 0;

    &, &:after{
      @include cover();
    }

    &:after{
      @include content();
      background: var(--black);
      opacity: 0.8;
    }
  }
}