.Intro {
  @include cover(fixed);
  @include flex(center, center);
  z-index: 2000;
  // transform: translateY(-100%);
  background: $base;
  // visibility: hidden;
  overflow: hidden;

  // body.is--animating & {
  //   visibility: visible;
  // }
}
