.Form {
  .is--error {
    color: $error-color;
  }

  .row {}

  label:not(.Checkbox):not(.CheckboxWithIcon) {
    display: block;
    margin-bottom: 0.85em;
    color: var(--black);

    sup{
      color: var(--main-color);
    }
  }

  &__group {
    position: relative;
    width: 100%;

    span.is--error {
      position: absolute;
      top: 100%;
      left: 0;
      font-size: 1rem;
      padding-top: 2px;
    }

    &.--message{
      @include flex();
      flex-direction: column;

      > textarea{
        flex: 1;
      }
    }

    .select-container{
      position: relative;

      .Icon[data-name="chevron"]{
        @include flex(center, center);
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background: var(--white);
        position: absolute;
        top: 50%; right: 0;
        transform: translate(-50%, -50%);
        pointer-events: none;

        svg {
          all: unset;
          min-width: 8px;
          width: get-vw(8);

          > *{
            stroke: var(--black);
          }
        }
      }
    }
  }

  &__control {
    width: 100%;
    border-radius: var(--radius);
    // border: 1px solid rgba(255, 255, 255, 0.1);
    font: 400 16px var(--main-font-text);
    color: var(--black);
    min-height: 4em;
    padding: 0 1.85em;
    transition: border-color 200ms var(--ease-out-cubic);
    background: var(--grey-l-d1);

    @include responsive($tabletSmall){
      @include font-vw(16);
    }

    // &:focus,
    // &:active{
    //   border-color: rgba(255, 255, 255, 0.35);
    // }

    .is--error &{ border-color: $error-color }

    &::placeholder { color: rgba(12, 12, 12, 0.3) }
  }

  select.Form__control {
    padding-top: 1.25em;
    padding-right: 3em;
  }

  textarea.Form__control {
    padding: 1.35em 1.25em;
  }

  // &__actions {
  //   .Btn {
  //     margin-top: 0;
  //   }
  // }

  &__output {
    color: $valid-color;

    p:not(:empty) {
      margin-top: 20px;
    }
  }

  &__actions{
    @include responsive($tabletSmall, max){
      margin-top: 2em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(30);
    }
  }
}
