.RealisationsList{
  > .container{
    @include flex();
    flex-direction: column;
  }

  .grid{
    display: grid;

    @include responsive($tabletSmall, max){
      grid-gap: 1.5em;
    }

    @include responsive($mobile){
      grid-template-columns: repeat(2, 1fr);
    }

    @include responsive($tabletSmall){
      grid-gap: get-vw(30);
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .Btn{
    align-self: center;

    @include responsive($tabletSmall, max){
      margin-top: 3em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(90);
    }

    @include noTouchHover() {
      .Chevron{
        transform: translate(0, 20%);
      }
    }

    .Chevron{
      transform: translate(0%, 0%) rotate(0deg);
    }
  }

  // filters categories :
  ._categories{

    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .title{
      font-size: 1.6666666667vw;
      font: 500 16px var(--main-font-title);
      color: var(--main-color);
      margin-right: 5px;    white-space: nowrap;
    }

    .tags{
      display: flex;
      flex-wrap: wrap;
      row-gap: 5px;

      li{
        margin-left: 2px;
        margin-right: 2px;
        cursor: pointer;
        &.active a{
          background-color: var(--main-color);
          color: #fff;
        }
      }
    }


  }
}
