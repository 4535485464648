.Timeline{
  padding-bottom: 0;

  .container{
    &:before{
      @include content();
      height: 100%;
      width: 1px;
      background: var(--black);
      position: absolute;
      top: 0; 
      opacity: 0.2;

      @include responsive($tabletSmall, max){
        left: 2.5vw;
      }

      @include responsive($tabletSmall){
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }
}