.PageHeaderRealisation{
  padding-bottom: get-vw(150);

  @include responsive($tabletSmall){
    padding-bottom: get-vw(100);
  }

  > .visual__container{
    @include responsive($tabletSmall, max){
      @include imgRatio(1440, 1000, var(--black));
    }

    @include responsive($tabletSmall){
      @include imgRatio(1440, 600, var(--black));
    }
  }

  .wrapper{
    @include flex(center, center);
    flex-direction: column;
    margin-top: -5em;

    @include responsive($tabletSmall, max){
      padding: 50px get-vw(50);
    }

    @include responsive($tabletSmall){
      padding: get-vw(80);
    }

    .back{
      &, .Icon__container{
        @include flex(center, center);
      }

      .Icon__container{
        height: get-vw(24);
        width: get-vw(24);
        min-height: 24px;
        min-width: 24px;
        border-radius: 50%;
        background: var(--grey-l-d1);
        transform: rotate(90deg);
        margin-right: 0.5em;

        .Icon[data-name="chevron"]{
          min-width: 10px;
        }
      }
    }

    h1, h2, .wswyg--content{
      width: 100%;

      @include responsive($tabletSmall){
        margin: 0 auto;
      }
    }

    h1, h2{
      text-align: center;
      max-width: space(14);
    }

    h1{
      @include responsive($tabletSmall, max){
        margin-top: 1.25em;
      }

      @include responsive($tabletSmall){
        @include font-vw(50);
        margin-top: get-vw(40);
      }
    }

    h2{
      font: 500 25px var(--main-font-text);
      text-transform: initial;
      color: #3f3f3f;
      margin: 0.7em 0 0;

      @include responsive($tabletSmall){
        @include font-vw(25);
      }
    }

    .tags{
      @include flex();
      flex-wrap: wrap;
      gap: 0.5em;
      margin-top: 2em;
      
      @include responsive($tabletSmall){
        max-width: get-vw(550);
      }
    }

    .wswyg--content{
      @include responsive($tabletSmall, max){
        margin-top: 2em;
      }

      @include responsive($tabletSmall){
        max-width: space(10);
        margin-top: get-vw(80);
      }
    }
  }

  .gallery{
    @include responsive($tabletSmall, max){
      margin-top: 2em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(100);
    }

    .swiper-navigation{
      margin-bottom: 1.5em;

      @include responsive($tabletSmall){
        margin-bottom: get-vw(40);
      }
    }

    .swiper-slide{
      @include responsive($tabletSmall, max){
        max-width: 80%;
      }

      @include responsive($tabletSmall){
        max-width: space(7);
      }

      & + .swiper-slide{
        @include responsive($tabletSmall, max){
          margin-left: 30px;
        }

        @include responsive($tabletSmall){
          margin-left: get-vw(30);
        }
      }
    }

    .visual__container{
      @include imgRatio(420, 444, var(--grey-l));
    }
  }
}