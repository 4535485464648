.Chevron{
  @include flex(center, center);
  height: get-vw(64);
  width: get-vw(64);
  min-height: 50px;
  min-width: 50px;
  border-radius: 50%;
  transform: rotate(-90deg);

  .Icon[data-name="chevron"]{
    min-width: 14px;
  }

  &.--light{
    background: var(--white);

    .Icon[data-name="chevron"]{
      svg > *{ stroke: var(--black) }
    }
  }

  &.--dark{
    background: var(--black);

    .Icon[data-name="chevron"]{
      svg > *{ stroke: var(--white) }
    }
  }
}