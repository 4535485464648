.RealisationConclusion{
  padding-top: 0;

  > .visual__container{
    @include imgRatio(1440, 685, var(--black));
  }

  > .wrapper{
    @include responsive($tabletSmall, max){
      padding: 3.5em 0;
    }

    @include responsive($tabletSmall){
      padding: get-vw(170) 0 get-vw(130);
    }
  }

  .title-scroll{
    padding-top: get-vw(150);

    @include responsive($tabletSmall) {
      padding-top: get-vw(100);
    }
  }

  .wswyg--content{
    @include responsive($tabletSmall, max){
      margin: 3em 0;
    }

    @include responsive($tabletSmall){
      max-width: space(12);
      margin: get-vw(100) auto;
    }

    *{
      color: var(--white);
    }
  }

  .Numbers{
    .ttl{ color: var(--white) }
  }
}
