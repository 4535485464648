#Outdated {

  position: fixed;
  top:0; left:0;
  width:100%;
  height:100%;
  z-index:2400;
  background:$base;
  @include flex();
  align-items:center;
  justify-content:center;

  &:not(.show) {
    display:none;
  }

  .inner {
    color:$white;
    text-align:center;
  }

  h4 {
    margin-bottom:0px;
  }

  .Btn {
    background:$white;

    .Btn__txt {
      color: $base
    }
  }

}
