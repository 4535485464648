// Circular Book
@font-face {
  font-family: circular;
  font-weight: 400;
  font-style: normal;
  font-display: block;
  src: url(../fonts/circular-b.woff2) format("woff2"), url(../fonts/circular-b.woff) format("woff");
}

// Circular Medium
@font-face {
  font-family: circular;
  font-weight: 500;
  font-style: normal;
  font-display: block;
  src: url(../fonts/circular-m.woff2) format("woff2"), url(../fonts/circular-m.woff) format("woff");
}

// Dolce Vita Bold
@font-face {
  font-family: dolce-vita;
  font-weight: 700;
  font-style: normal;
  font-display: block;
  src: url(../fonts/dolce-vita.woff2) format("woff2"), url(../fonts/dolce-vita.woff) format("woff");
}

// Housettely Signature
@font-face {
  font-family: housetelly-signature;
  font-weight: 400;
  font-style: normal;
  font-display: block;
  src: url(../fonts/housettely-signature.woff2) format("woff2"), url(../fonts/housettely-signature.woff) format("woff");
}
