@import url("../../node_modules/locomotive-scroll/dist/locomotive-scroll.css");

.c-scrollbar {
  right: 0px;
  top: 5px;
  height: calc(100vh - 10px);

  &_thumb {
    width: 3px;
    margin: 0 5px;
  }
}


[data-scroll-direction="horizontal"] {

  .Footer {
    display: none;
  }

  [data-scroll-container] {
    position: fixed;
    top: 0;
    left: 0;
  }

  .c-scrollbar {
    width: calc(100vw - 10px);
    height: 3px;
    right: 5px;
    bottom: 5px;

    &_thumb {
      margin: 0;
      height: 3px;
    }
  }
}

html.has-scroll-smooth {
  @include cover(fixed);

  [data-scroll-container] {
    will-change:transform;
  }
}
