.App {
  width: 100%;
  min-height: 100vh;
  background: $white;
  position: relative;
  z-index: 10;

  html:not(.no-js) body.is--loading & {
    pointer-events: none;
  }

  > * {
    width: 100%;

    & + * {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.container {
  width: 100%;
  position: relative;
  padding: 0 calc(100vw / 24);

  @include responsive($tabletSmall) {
    padding: 0 calc(100vw / 12);
  }

  &.--col-1{
    padding: 0 calc(100vw / 24);

    @include responsive($tabletSmall) {
      padding: 0 calc(100vw / 24);
    }
  }
}

section {
  padding: get-vw(150) 0;

  @include responsive($tabletSmall) {
    padding: get-vw(100) 0;
  }
}