.Quote{
  text-align: center;
  margin: 0 auto;

  @include responsive($tabletSmall, max){
    width: 80%;
  }

  @include responsive($tabletSmall){
    width: get-vw(660);
  }

  > .visual__container{
    @include imgRatio(400, 250);
    mix-blend-mode: darken;
    max-width: get-vw(200);
    margin: 0 auto 1.5em;
    overflow: hidden;
    
    .visual{
      @include flex(center, center);
      background: var(--white);
    }

    img{
      height: 80%;
      width: 80%;
      object-fit: contain;
    }
  }

  > blockquote{
    p{
      font: 400 20px var(--main-font-text);

      @include responsive($tabletSmall){
        @include font-vw(20);
      }
    }
  }

  > figcaption{
    color: var(--text-color);
    margin-top: 1.5em;
  }
}