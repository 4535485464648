/* -----
Variables CSS
----- */
:root{
  // Colors
  --white: #ffffff;
  --black: #0C0C0C;
  --base: var(--black);
  --grey: #999999;
  --grey-l: #F7F7F7;
  --grey-l-d1: #EDEDED;
  --grey-l-d2: #E6E6E6;
  --dark-l: #151515;
  
  // Public Address
  --coral: #FF6B80;
  --coral-d1: #f87488;
  --coral-d2: #CC5666;

  // Private Address
  --gold: #E5C2A4;
  --coral-d1: #d9b89b;
  --coral-d2: #c0a084;

  // Stagers
  --pink: #E62580;

  // Theme for the website
  --main-font-title: dolce-vita, serif;
  --main-font-text: circular, serif;
  --main-color: var(--gold);
  --main-color-hover: var(--gold-d1);
  --main-color-d: var(--gold-d2);
  --text-color: #626262;

  // Settings
  --font-color: var(--base);
  --links-color: #96dacd;
  --error-color: #dc3838;
  --valid-color: #5da854;

  // This is updated with JS with : get-full-height.js
  --vh: 100%;

  // Easings
  --ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  --ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  --ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  --ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  --ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
  --ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
  --ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
  --ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
  --ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
  --ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  --ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
  --ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
  --ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
  --ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

/* -----
DELETE THIS
----- */
$white: #ffffff;
$black: #1a1a1a;
$grey: #999999;
$grey-l: #eeeeee;
$base: $black;

$font-color: $base;
$links-color: #96dacd;
$error-color: #dc3838;
$valid-color: #5da854;

/* -----
Paths
----- */
$base-images:"../images/";
$base-fonts:"../fonts/";


/* -----
Typography, WebFonts
----- */
$lato: 'Lato', sans-serif;

/* -----
Grid
----- */
$base-vw: 1440;
$base-grid: 24;
$browser-context: 18;

/* -----
CSS Easings
----- */
$transitionDuration : 600ms;
$ease : cubic-bezier(0.36,0.33,0,1);
$snap: cubic-bezier(0,1,.5,1);
$easeOutCubic: cubic-bezier(.215,.61,.355,1);
$easeInOutCubic: cubic-bezier(.645,.045,.355,1);
$easeInCirc: cubic-bezier(.6,.04,.98,.335);
$easeOutCirc: cubic-bezier(.075,.82,.165,1);
$easeInOutCirc: cubic-bezier(.785,.135,.15,.86);
$easeInExpo: cubic-bezier(.95,.05,.795,.035);
$easeOutExpo: cubic-bezier(.19,1,.22,1);
$easeInOutExpo: cubic-bezier(1,0,0,1);
$easeInQuad: cubic-bezier(.55,.085,.68,.53);
$easeOutQuad: cubic-bezier(.25,.46,.45,.94);
$easeInOutQuad: cubic-bezier(.455,.03,.515,.955);
$easeInQuart: cubic-bezier(.895,.03,.685,.22);
$easeOutQuart: cubic-bezier(.165,.84,.44,1);
$easeInOutQuart: cubic-bezier(.77,0,.175,1);
$easeInQuint: cubic-bezier(.755,.05,.855,.06);
$easeOutQuint: cubic-bezier(.23,1,.32,1);
$easeInOutQuint: cubic-bezier(.86,0,.07,1);
$easeInSine: cubic-bezier(.47,0,.745,.715);
$easeOutSine: cubic-bezier(.39,.575,.565,1);
$easeInOutSine: cubic-bezier(.445,.05,.55,.95);
$easeInBack: cubic-bezier(.6,-.28,.735,.045);
$easeOutBack: cubic-bezier(.175, .885,.32,1.275);
$easeInOutBack: cubic-bezier(.68,-.55,.265,1.55);
$easeSwiper : cubic-bezier(0.36,0.33,0,1);


// GSAP Easings
// from: https://github.com/raphaelameaume/gsap-easings-css/blob/master/easings.scss
$Power0EaseNone: cubic-bezier(0.250, 0.250, 0.750, 0.750);
$Power1EaseIn: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$Power1EaseOut: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$Power1EaseInOut: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$Power2EaseIn: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$Power2EaseOut: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$Power2EaseInOut: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$Power3EaseIn: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$Power3EaseOut: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$Power3EaseInOut: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$Power4EaseIn: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$Power4EaseOut: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$Power4EaseInOut: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$CircEaseIn: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$CircEaseOut: cubic-bezier(0.075, 0.820, 0.165, 1.000); // wip
$CircEaseInOut: cubic-bezier(0.785, 0.135, 0.150, 0.860); // wip
$ExpoEaseIn: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$ExpoEaseOut: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$ExpoEaseInOut: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$SineEaseIn: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$SineEaseOut: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$SineEaseInOut: cubic-bezier(0.445, 0.050, 0.550, 0.950);

/* -----
Media Queries
----- */

$mobileSmall:330px;
$mobile:550px;
$tabletSmall:768px;
$tablet:992px;
$desk:1200px;
$deskXL:1460px;
$deskXXL:1680px;
