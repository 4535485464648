.Loader {
  @include cover(fixed);
  z-index: 100;
  background: $white;
  visibility: hidden;
  overflow: hidden;

  body.is--intro & {
    visibility:hidden!important;
  }
}
