.Socials{
  @include flex(flex-start);

  @include responsive($tabletSmall, max){
    gap: 15px;
  }
  
  @include responsive($tabletSmall){
    gap: get-vw(15);
  }
}