a:not([class]) {
  color: var(--main-color);
  text-decoration: underline;
  font-weight: 500;
}

a[class] {
  color: inherit;
  text-decoration: none;
}

.Anchors {
  &__nav {
    @include flex();
    justify-content: flex-start;
    margin-top: 30px;
    margin-bottom: -10px;
  }

  &__item {
    margin-right: 20px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.Link {
  position: relative;
  font-size: 2.4rem;

  &.--icon{
    display: inline-flex;

    .Icon{
      margin-top: -0.3em;
      margin-left: 0.3em;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 100%; left: 0;
    width: 100%; height: 1px;
    background: $base;
    transform: scaleX(0);
    transform-origin: 100% 100%;
    transition: transform 400ms $easeInOutQuad 50ms;

    .Footer &{
      background: var(--white);
    }
  }
  
  &:hover {
    &:before {
      transform: scaleX(1);
      transform-origin: 0% 0%;
    }
  }
}
