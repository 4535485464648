.RealisationCard {
  &.--all{
    @include flex();
    // padding-top: 465px;
    height: 100%;
    background: var(--main-color);
    transition: clip-path 350ms var(--ease-in-out-cubic);
    clip-path: inset(0% 0% 0% 0%);
    
    @include noTouchHover() {
      clip-path: inset(1% 1% 1% 1%);

      > .wrapper{
        .Chevron{
          transform: scale(0.7) rotate(-90deg);
        }
      }
    }

    > .wrapper{
      @include flex(flex-start);

      .Chevron{
        margin-bottom: 5em;
        transition: transform 350ms var(--ease-in-out-cubic) 50ms;

        @include responsive($tabletSmall){
          margin-bottom: get-vw(350);
        }
      }

      span{
        margin-top: auto;
        margin-bottom: 0;
      }
    }
  }

  &:not(.--all){
    @include flex();
    flex-direction: column;

    @include responsive($tabletSmall, max){
      height: 100%;
    }

    @include noTouchHover() {
      .visual__container{
        .visual{
          transform: scale(1.1);
        }
      }

      .discover{
        > span{
          transform: translate(10%, 0);
          clip-path: inset(0% 0% 0% 0%);
        }

        > .Chevron{
          transform: translate(10%, 0%) rotate(-90deg);
        }
      }
    }
  }

  .visual__container{
    @include imgRatio(420, 260, var(--black));
    overflow: hidden;
    
    .visual{
      transition: transform 750ms var(--ease-out-quint);
    }
  }

  .wrapper {
    @include flex();
    flex-direction: column;
    flex: 1;
    padding: 2em;

    > .tags{
      @include flex();
      margin-bottom: 1em;
    }

    > span{
      font: 700 30px var(--main-font-title);
      margin-bottom: 0.5em;

      @include responsive($tabletSmall){
        @include font-vw(30);
      }
    }

    > p {
      font: 400 16px var(--main-font-text);
      color: var(--text-color);
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      margin-bottom: 2em;
    }

    .discover{
      @include flex(center);
      margin-top: auto;

      > span{
        font: 400 14px var(--main-font-title);
        clip-path: inset(0% 100% 0% 0%);
        transition: transform 450ms var(--ease-out-quint) 50ms,
                    clip-path 450ms var(--ease-out-quint) 50ms;

        @include responsive($tabletSmall){
          @include font-vw(14);
        }
      }

      > .Chevron{
        transition: transform 450ms var(--ease-out-quint);
        margin-right: 1em;
      }
    }
  }
}
