.ContentCreation{
  @include responsive($tabletSmall){
    padding-bottom: get-vw(150);
  }

  .subtitle.--big{
    text-align: center;
  }

  .wswyg--content{
    @include responsive($tabletSmall, max){
      margin: 3em 0;
    }

    @include responsive($tabletSmall){
      max-width: space(12);
      margin: get-vw(100) auto 0;
    }

    *{
      color: #acacac;
    }
  }
}