.Partners{
  > .container{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }
  }

  .wrapper{
    @include flex();
    flex-direction: column;
    
    @include responsive($tabletSmall){
      margin-left: space(1);
      max-width: space(6);
    }
  }

  h2{
    font: 700 20px var(--main-font-title);
    text-align: left;

    @include responsive($tabletSmall){
      @include font-vw(40);
      line-height: 1.1;
    }
  }

  .wswyg--content{
    @include responsive($tabletSmall, max){
      margin-top: 2em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(25);
    }

    *{ color: var(--text-color) }
  }

  .swiper{
    margin-left: auto;
    margin-right: 0;
    width: 100%;
    overflow: hidden;

    @include responsive($tabletSmall, max){
      margin-top: 2em;
    }
    
    @include responsive($tabletSmall){
      height: get-vw(600);
      max-width: get-vw(780);
    }

    &-navigation{
      @include responsive($tabletSmall, max){
        margin-top: 2em;
      }

      @include responsive($tabletSmall){
        margin-top: get-vw(50);
      }

      > *{
        background: var(--white);
      }
    }

    &-slide{      
      @include imgRatio(180, 180, var(--white));
      text-align: center;
      font-size: 18px;
      background: var(--white);
      // max-width: 180px;
      width: get-vw(180);

      &:before{
        @include content();
        padding-top: 100%;
      }

      > *,
      .visual__container{
        @include cover();
      }

      .visual__container{
        // min-width: 180px;

        > .visual{
          width: get-vw(100);
          height: get-vw(80);
          min-width: 100px;
          min-height: 80px;
          top: 50%; left: 50%;
          transform: translate(-50%, -50%);

          img{
            object-fit: contain;
          }
        }
      }
    }
  }
}
