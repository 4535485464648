.Logo{
  @include imgRatio(115, 48);
  width: get-vw(115);
  min-width: 115px;
  position: relative;

  @include responsive($tabletSmall, max){
    min-width: 90px;
  }

  .Header &{
    @include responsive($tablet){
      min-width: get-vw(130);
    }
  }
  
  .Intro &{
    @include responsive($tablet){
      min-width: 130px;  
    }
  }

  .PageTransition &{
    max-width: get-vw(245);
  }

  svg{
    @include cover();

    > *{
      fill: var(--black);

      .Intro &, 
      .Footer &,
      .PageTransition &{
        fill: var(--white);
      }
    }
  }
}