.ContactForm{
  background: var(--grey-l);
  padding: 120px 0;

  .Form.--show-estimations{
    .row{
      @include responsive($tabletSmall){
        grid-template-areas: 
          'names subject'
          'society grid'
          'email message'
          'tel message'
          'empty bottom'
        ;
      }
    }

    .--grid{
      display: grid;
    }
  }

  .row{
    display: grid;
    padding: 0 space(1);

    @include responsive($tabletSmall, max){
      grid-gap: 1.5em;
      grid-template-areas: 
        "names"
        "society"
        "email"
        "tel"
        "subject"
        "grid"
        "message"
        "bottom"
      ;  
    }
    
    @include responsive($tabletSmall){
      grid-gap: get-vw(30) get-vw(60);
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: 
        'names subject'
        'society message'
        'email message'
        'tel message'
        'empty bottom'
      ;
    }
  }

  .--names{
    grid-area: names;
  }

  .--society{
    grid-area: society;
  }

  .--email{
    grid-area: email;
  }

  .--tel{
    grid-area: tel;
  }

  .--subject{
    grid-area: subject;
  }

  .--grid{
    grid-area: grid;
    display: none;
    
    @include responsive($mobile, max){
      grid-gap: 1.5em;
    }
    
    @include responsive($mobile){
      grid-template-columns: repeat(2, 1fr);
      grid-gap: get-vw(30);
    }

    > *{
      flex-direction: column;

      .Form__control{
        align-items: center;
      }
    }
  }

  .--message{
    grid-area: message;

    .Form__control{
      @include responsive($tabletSmall, max){
        min-height: 200px;
      }
    }
  }

  .--bottom{
    grid-area: bottom;
  }
}
