.Cursor {
  @include flex(center, center);
  position: fixed;
  top: 0; left: 0;
  width: 50px;
  height: 50px;
  z-index: 1000;
  pointer-events: none;
  // mix-blend-mode: exclusion;

  &.--hovering{
    .Cursor__custom{
      transform: scale(0);
    }
  }

  &__custom {
    transition: transform 400ms $ease;

    &, svg{
      @include cover();
    }

    > svg{
      fill: var(--main-color);
    }
  }

  body.is--loading & {
    .Cursor__custom {
      transform: scale(0);
    }
  }
}
