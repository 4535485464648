.DiscoverSection{
  @include flex(flex-start);

  @include responsive($tabletSmall, max){
    flex-direction: column-reverse;
    align-items: center;
  }

  @include responsive($tabletSmall){
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    // position: sticky;
    // top: 4%;
    // padding-top: 20vh;
  }

  & + .DiscoverSection{
    @include responsive($tabletSmall, max){
      margin-top: 6.5em;
    }
  }
  
  .wrapper{
    @include flex(flex-start);
    flex-direction: column;
    width: 100%;

    @include responsive($tabletSmall, max){
      align-items: center;
      text-align: center;
    }

    p{
      color: var(--text-color);

      @include responsive($tabletSmall, max){
        margin-top: 1.2em;
      }

      @include responsive($tabletSmall){
        margin-top: get-vw(25);
      }
    }

    .Btn{
      @include responsive($tabletSmall, max){
        margin-top: 3em;
      }

      @include responsive($tabletSmall){
        margin-top: get-vw(40);
      }
    }
  }

  .visual__container{
    @include imgRatio(440, 560);
    
    @include responsive($tabletSmall, max){
      margin-bottom: 2em;
      max-width: 70%;
    }
    
    @include responsive($tabletSmall){
      display: none;
    }
  }
}