h1, .title {
   font: 700 10vw var(--main-font-title);
   line-height: 1.1;
   text-align: center;

   > .--oh{
      margin-top: -0.15em;
   }

   .subtitle + &{
      margin-top: 0.25em;
   }

   @include responsive($tabletSmall){
     @include font-vw(120);
   }
}

h2{
   font: 700 8vw var(--main-font-title);
   text-align: center;

   &:not(.--mid) {
      line-height: 1;
      
      @include responsive($tabletSmall){
        @include font-vw(100);
      }
   }

   &.--mid{
      line-height: 0.95;

      @include responsive($tabletSmall){
        @include font-vw(70);
      }
   }

   &.--big{
      font: 700 13vw var(--main-font-title);

      @include responsive($tabletSmall){
        @include font-vw(150);
      }
   }
}

p {
   font-size: 16px;
   line-height: 1.6;

   @include responsive($tabletSmall){
     @include font-vw(16);
   }

   &.no__results {
     text-align: center;
     font-size: 3.2rem;
     font-weight: bold;
     opacity: 0.2;
     margin-top: 30px;
   }
}

.title-scroll{
   display: inline-flex;
   white-space: nowrap;

   i {
      align-self: center;
      color: var(--main-color);
      height: 0.65em;
      margin: 0 0.25em; 
   }
}

.subtitle{
   text-transform: uppercase;
   
   &:not(.tag){
      color: var(--main-color);
   }

   &:not(.--big):not(.--mid){
      font: 500 13px var(--main-font-text);

      @include responsive($tabletSmall){
        @include font-vw(13);
      }
   }

   &.--big{
      font: 500 20px var(--main-font-title);

      @include responsive($tabletSmall){
        @include font-vw(40);
      }
   }

   &.--mid{
      font: 500 16px var(--main-font-title);

      @include responsive($tabletSmall){
        @include font-vw(24);
      }
   }

   &.--c-black{
      color: var(--black);
   }
}

.tag{
   border-radius: 20em;
   border: 1px solid rgba(12, 12, 12, 0.1);
   padding: 6px 10px;
}

.step{
   font: 500 14px var(--main-font-text);
   background: var(--grey-l);
   border-radius: 20em;
   padding: 1.2em 2.4em;

   @include responsive($tabletSmall){
     @include font-vw(14);
   }
 }

.wswyg--content {
  text-align: left;

  &.--c-black{
   *{
      color: var(--black);
   }
  }

  &.--big{
   ul, ol, li, p{
      font: 400 22px var(--main-font-text);
      line-height: 1.1;

      @include responsive($tabletSmall){
        @include font-vw(40);
      }
   }
  }

   > *+* {
      margin-top: 1em;
   }

   * + h2 {
     margin-top: 1.5em;
   }

   h2 {
      font: 500 42px var(--main-font-text);

      @include responsive($tabletSmall){
        @include font-vw(42);
      }

   //   margin-bottom: 1.25em;
   }

   h3{
      font: 500 40px var(--main-font-text);

      @include responsive($tabletSmall){
        @include font-vw(40);
      }
   }

   h4{
      font: 500 38px var(--main-font-text);

      @include responsive($tabletSmall){
         @include font-vw(38)
      }
   }

   h5{
      font: 500 36px var(--main-font-text);

      @include responsive($tabletSmall){
         @include font-vw(36)
      }
   }

   h6{
      font: 500 34px var(--main-font-text);

      @include responsive($tabletSmall){
         @include font-vw(34)
      }
   }

   ul, ol, li, p{
      color: var(--text-color);
   }

   ul, ol {
      @extend p;
      margin-top: 1em;
      line-height: 1.6;

      > li+li {
         margin-top: 1em;
      }

      code {
         margin: 1em 0;
      }
   }

   ul{
      list-style: none;

      > li{
         &:before{
            content: '.';
            margin-right: 0.2em;
         }
      }
   }

   ol {
     list-style-type: decimal;
     padding-left: 2em;
   }

   blockquote{
      border-left: 4px solid var(--main-color);
      margin: 1.3em 0;
      padding-left: 2em;

      > p{
         font: 400 30px var(--main-font-text);

         @include responsive($tabletSmall){
            @include font-vw(30);
         }
      }
   }

   p {
      font-weight: 400;

      strong {
         color:$base;
      }
   }

   strong{
      font-weight: 500;
      color: var(--black);
   }

   figure {
     margin: 2em auto;
     overflow: hidden;

     &:last-child { margin-bottom: 0 }
     
     img { display: block }
   }

   a {
      color: $links-color;
      font-weight: 700;
      text-decoration: underline;
   }
}
