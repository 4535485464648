.ContactBanner{
  background: var(--main-color);
  z-index: 1;

  > .container{
    @include flex(flex-start);
    flex-direction: column;

    @include responsive($tabletSmall, max){
      align-items: center;
      justify-content: center;
    }
  }

  h2{
    filter: opacity(0.4);

    @include responsive($tabletSmall, max){
      text-align: center;
    }
    
    @include responsive($tabletSmall){
      @include font-vw(70);
      text-align: left;
    }
  }

  a{
    font: 700 8vw var(--main-font-title);

    @include noTouchHover() {
      .Icon__container{
        transform: translate(80%, 0) scale(0.8) rotate(-90deg);
      }
    }
  }

  h2, a{
    @include responsive($tabletSmall){
      @include font-vw(70);
    }
  }

  .Icon__container{
    @include flex(center, center);
    height: get-vw(72);
    width: get-vw(72);
    min-height: 70px;
    min-width: 70px;
    border-radius: 50%;
    background: var(--black);
    transform: rotate(-90deg);
    transition: transform 450ms var(--ease-in-out-quart),
                opacity 450ms var(--ease-in-out-quart);

    @include responsive($tabletSmall, max){
      margin: 1em auto 0;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(20);
    }

    .Icon[data-name="chevron"]{
      min-width: 20px;

      svg > *{
        stroke: var(--white);
      }
    }
  }
}