.Checkbox {
  @include flex(flex-start, flex-start, $wrap: nowrap);
  position: relative;
  cursor: pointer;

  input{
    position: absolute;
    top: 0; left: 0;
    opacity: 0;
    z-index: 10;
  }

  input,
  &__mark{
    min-width: 24px;
    min-height: 24px;
    width: get-vw(24);
    height: get-vw(24);
  }

  &__label{
    font: 400 14px var(--main-font-text);
    line-height: 1.45;
    color: var(--text-color);
    flex: 1;
    padding: 0.2em 0 0 1em;

    
    @include responsive($tabletSmall){
      @include font-vw(14);
    }

    > a{
      color: var(--black);
    }
  }

  &__mark{
    position: relative;
    background: var(--grey-l-d1);
    transition: outline-color 200ms $SineEaseInOut 50ms, 
                background 200ms $SineEaseInOut 50ms;

    .Icon[data-name="tick"]{
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 200ms $SineEaseInOut 50ms;
    }

    input:checked ~ & {
      background: var(--main-color);

      .Icon[data-name="tick"]{
        opacity: 1;
      }
    }
  }
}
