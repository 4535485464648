.Discover{
  padding: 0;

  @include responsive($tabletSmall, max){
    padding-bottom: 2em;
  }
  
  > .container{
    @include flex(flex-start);
  }

  // Only for content (on large screens)
  .sections{
    height: 100%;

    @include responsive($tabletSmall){
      max-width: space(7);
      padding-top: get-vw(200);
      padding-bottom: get-vw(200);
    }
  }

  // Index container + Visuals container
  .desk-animations{
    @include flex(flex-start);
    width: 100%;
    max-width: space(11);
    margin-left: auto;
    position: sticky;
    top: 0; 
    height: 100vh;
    padding: 15vh 0;

    @include responsive($tabletSmall, max){
      display: none;
    }
  }

  // Visuals container (only displayed on large screens)
  .visuals{
    @include flex(center, flex-end);
    width: 100%;
    height: 100%;
    margin-left: auto;
    @include imgRatio(440, 560, var(--grey));
    max-width: get-vw(440);
    position: relative;

    > *:not(:first-child){
      position: absolute;
    }

    .visual__container{
      @include cover();
      background: var(--black);
      // @include imgRatio(440, 560, var(--grey));
      // max-width: get-vw(440);
    }
  }

  // Numbers (only displayed on large screens)
  .index{
    font: 700 5vw var(--main-font-title);
    @include font-vw(200);
    color: var(--main-color);
    line-height: 0.8;

    @include responsive($tabletSmall){
      @include font-vw(185);
      margin-left: 0.15em;
    }

    &-active{
      > span{
        &:not(:first-child){
          position: absolute;
          top: 0; left: 0;
        }
      }
    }
  }
}
