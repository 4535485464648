.Numbers{
  @include flex();

  @include responsive($tabletSmall, max){
    align-items: center;
    justify-content: center;
    gap: 4em;  
    flex-direction: column;
  }

  @include responsive($tabletSmall){
    gap: get-vw(30);
  }
  
  > div{
    text-align: center;
    width: 100%;

    @include responsive($tabletSmall){
      flex: 1 0 33.33333%;
      max-width: 33.33333%;
    }
  }

  .num{
    color: var(--main-color);

    @include responsive($tabletSmall, max){
      font-size: 20vw;
    }
  }

  .ttl{
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    font: 400 20px var(--main-font-text);
    letter-spacing: -0.02em;
    min-width: 100%;

    @include responsive($tabletSmall){
      @include font-vw(40);
    }
  }
}