.Vocation{
  > .visual__container{
    @include imgRatio(1440, 740, var(--black));
  }

  .wrapper{
    @include flex(center, center);
    flex-direction: column;
    background: var(--main-color);
    margin-top: -15%;

    @include responsive($tabletSmall, max){
      padding: 2em 3vw;
    }

    @include responsive($tabletSmall){
      padding: get-vw(100) space(4);
    }

    .subtitle{
      margin-bottom: 1.2em;
    }

    h2{
      font: 400 4vw var(--main-font-text);

      @include responsive($tabletSmall){
        @include font-vw(40);
        line-height: 1.1;
      }
    }

    .Btn{
      @include responsive($tabletSmall, max){
        margin-top: 1em;
      }

      @include responsive($tabletSmall){
        margin-top: get-vw(35);
      }
    }
  }
}