.Testimonial{
  .swiper{
    &-slide{
      transition: opacity 1000ms var(--ease-out-circ) !important;
      
      &:not(.swiper-slide-active){
        opacity: 0 !important;
      }
    }

    &-navigation{
      margin-top: 3em;
      order: 2;
      justify-content: center;

      @include responsive($tabletSmall){
        margin-top: get-vw(50);
      }
    }
  }
}