.Values{
  .subtitle{
    margin-bottom: 1em;
    
    @include responsive($tabletSmall){
      margin-left: space(2);
    }
  }
  
  .wrapper{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }
  }

  nav{
    @include responsive($tabletSmall, max){
      width: 100%;
    }

    @include responsive($tabletSmall){
      max-width: space(11);
    }

    &, ul{
      @include responsive($tabletSmall, max){
        @include flex();
      }
    }

    ul{
      @include flex(flex-start);

      @include responsive($tabletSmall, max){
        gap: 1em;
        overflow-x: scroll;
        white-space: nowrap;
      }

      @include responsive($tabletSmall){
        flex-direction: column;
        gap: 0.75em;
      }
    }

    button{
      @include flex();
      font: 700 8vw var(--main-font-title);
      line-height: 0.88;
      cursor: pointer;
      transition: color 450ms var(--ease-in-out-quart);

      @include responsive($tabletSmall){
        @include font-vw(90);
      }

      &.--active{
        color: var(--main-color);

        > .cross{
          transform: rotate(-180deg);

          &:before, 
          &:after{
            background: var(--main-color);
          }

          &:after{
            opacity: 1;
          }
        }
      }

      > .cross{
        @include flex(center, center);
        width: get-vw(60);
        height: get-vw(60);
        min-width: 45px;
        min-height: 45px;
        margin-top: 0.05em;
        transition: transform 450ms var(--ease-in-out-quart);

        @include responsive($tabletSmall, max){
          display: none;
        }

        &:before,
        &:after{
          @include content();
          position: absolute;
          height: 4px;
          width: 100%;
          background: var(--black);
          transition: background 450ms var(--ease-in-out-quart);
        }

        &:after{
          transform: rotate(90deg);
          opacity: 0;
        }
      }

      > span{
        @include responsive($tabletSmall){
          padding-left: space(1);
          flex: 1;
        }
      }
    }
  }

  .tabs{
    width: 100%;

    @include responsive($tabletSmall, max){
      margin-top: 1.5em;
    }

    @include responsive($tabletSmall){
      max-width: space(7);
      margin-left: auto;
    }
  }

  .tab{
    transition: opacity 450ms var(--ease-in-out-quart),
                transform 450ms var(--ease-in-out-quart);

    &:not(.--active){
      position: absolute;
      opacity: 0;
      pointer-events: none;
      transition: none;
      transform: translate(0, 5%);
    }

    p{
      color: var(--text-color);

      @include responsive($tabletSmall, max){
        margin-bottom: 1.7em;
      }

      @include responsive($tabletSmall){
        margin-bottom: get-vw(40);
      }
    }

    .visual__container{
      @include imgRatio(420, 280, var(--white));
    }
  }

  .Numbers{
    position: relative;

    @include responsive($tabletSmall, max){
      margin-top: 4em;
      padding: 4em 0;
    }
    
    @include responsive($tabletSmall){
      margin-top: get-vw(250);
      padding: get-vw(70) 0;
    }

    &:before,
    &:after{
      @include content();
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      height: 1px;
      width: get-vw(500);
      min-width: 300px;
      background: rgba(0, 0, 0, 0.2);
    }

    &:before{ top: 0; }
    &:after{ bottom: 0; }

  }
}