.Icon {
  position: relative;
  @include imgRatio(1, 1);
  min-width: 24px;

  svg {
    @include cover();
    fill: $base;
  }

  &[data-name="facebook"],
  &[data-name="linkedin"]{
    @include imgRatio(16, 16);
    width: get-vw(16);
    min-width: 16px;
  }

  &[data-name="twitter"]{
    width: get-vw(24);
  }

  &[data-name="instagram"]{
    @include imgRatio(16, 16);
    width: get-vw(16);
    min-width: 16px;
  }

  &[data-name="welcome_to_the_jungle"]{
    @include imgRatio(16, 16);
    width: get-vw(16);
    min-width: 16px;
  }
  
  &[data-name="play"] {
    @include imgRatio(12, 15);
    min-width: 12px;
  }

  &[data-name="chevron"] {
    @include imgRatio(6, 3);
    min-width: 6px;
    width: get-vw(6);
  
    svg > *{
      fill: none;
      stroke: var(--black);
    }
  }

  &[data-name="tick"] {
    @include imgRatio(10, 7);
    min-width: 10px;
    width: get-vw(10);
    
    svg > *{ 
      fill: var(--black) 
    }
  }

  &[data-name="location"] {
    @include imgRatio(12, 14);
    min-width: 12px;
    width: get-vw(12);

    svg > *{ 
      fill: var(--white);
    }
  }

  &[data-name="star"] {
    @include imgRatio(38, 36);
    min-width: 38px;
    width: get-vw(38);
  }

  &[data-name="quote"]{
    @include imgRatio(179, 129);
    min-width: 60px;
    width: get-vw(179);

    svg > *{ fill: var(--main-color) }
  }

  &[data-name="message"]{
    @include imgRatio(24, 24);
    min-width: 20px;
    width: get-vw(24);

    svg > path{
      fill: none;
    }
  }

  &[data-name="arrow-exponent"]{
    @include imgRatio(6, 6);
    width: get-vw(6);
    min-width: 6px;
  }
}
