.Security{
  &, .wrapper{
    position: relative;
  }

  .wrapper{
    @include flex(flex-start);
    flex-wrap: wrap;
    z-index: 1;

    @include responsive($tabletSmall){
      margin: 0 space(1);
    }

    > h2{
      text-align: left;

      @include responsive($tabletSmall){
        max-width: space(5)
      }
    }

    > .wswyg--content{
      @include responsive($tabletSmall, max){
        margin-top: 2em;
      }

      @include responsive($tabletSmall){
        max-width: space(9);
        margin-left: auto;
      }

      *{ color: var(--grey-l-d1) }
    }
  }

  .visual__container{
    z-index: 0;

    &, &:after{
      @include cover();
    }

    &:after{
      @include content();
      background: var(--black);
      opacity: 0.8;
    }
  }
}