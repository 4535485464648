.Btn {
  all: unset;
  @include flex(center, center);
  cursor: pointer;
  text-align: center;
  padding: 1.85em;
  position: relative;
  overflow: hidden;

  &:before{
    @include content();
    @include cover();
    z-index: 0;
    background: var(--black);
    transform: scaleX(0);
    transform-origin: 100% 100%;
    transition: transform 450ms var(--ease-in-out-quart);
  }
  
  &:not(.--secondary){
    background: var(--main-color);
    color: var(--black);

    .Chevron{
      background: var(--black);

      svg > *{ stroke: var(--white) }
    }

    @include noTouchHover() {
      .Chevron{
        background: var(--white);

        .Icon[data-name="chevron"] svg > *{
          stroke: var(--black);
        }
      }

      .Icon[data-name="message"] svg {
        path { stroke: var(--white) }
        circle { fill: var(--white) }
      }
      
      > span{
        color: var(--white);
      }
    }
  }

  &.--secondary{
    background: var(--black);
    color: var(--white);

    &:before{
      background: var(--white);
    }

    .Chevron{
      background: var(--white);
    }

    @include noTouchHover() {
      .Chevron{
        background: var(--black);

        .Icon[data-name="chevron"] svg > *{
          stroke: var(--white);
        }
      }

      > span{
        color: var(--black);
      }
    }
  }

  @include noTouchHover() {
    &:before{
      transform-origin: 0% 0%;
      transform: scaleX(1);
    }

    .Chevron{
      transform: translate(20%, 0) rotate(-90deg);
    }

    span{
      transform: translate(2%, 0);
    }
  }

  .Chevron,
  .Icon[data-name="chevron"] svg *,
  .Icon[data-name="message"] svg *,
  span{
    transition: all 450ms var(--ease-in-out-quart) !important;
  }

  .Chevron{
    height: get-vw(24);
    width: get-vw(24);
    min-height: 24px;
    min-width: 24px;

    .Icon[data-name="chevron"]{
      min-width: 10px;
    }
  }

  > span{
    font: 500 14px var(--main-font-text);
    margin-left: 0.8em;
    transition-delay: 20ms;

    @include responsive($tabletSmall){
      @include font-vw(14);
    }
  }
}