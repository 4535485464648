.Nav{
  // <div id="Menu">...</div>
  &__container{
    @include cover(fixed);
    @include flex();
    background: var(--black);
    clip-path: inset(0% 0% 100% 0%);
    overflow-y: scroll;

    &, > .container > .wrapper{
      @include flex();
    }

    > .container > .wrapper{
      flex-direction: column;

      @include responsive($tabletSmall){
        height: 100%;
      }

      @media (max-height: 649px) {
        padding: 10em 0;
      }

      @media (min-height: 650px) {
        padding: 25vh 0 4em;
      }

    }
  }

  // <nav>...</nav>
  &__main{
    width: 100%;

    @include responsive($tabletSmall, max){
      margin-bottom: 3em;
    }

    @include responsive($tabletSmall){
      max-width: space(13);
      margin-left: auto;
      margin-right: space(1);
      padding-bottom: get-vw(50);
    }
  }

  // <a href="#">...</a>
  &__link{
    font: 700 7vw var(--main-font-title);
    display: inline-block;

    @include noTouchHover() {
      > span {
        opacity: 0.5;
      }
    }

    > span{
      transition: opacity 350ms var(--ease-out-quart);
    }

    @include responsive($tabletSmall){
      @include font-vw(65);
    }
  }
}
