.RelatedRealisations{
  h2{
    @include responsive($tabletSmall, max){
      text-align: center;
      margin-bottom: 1em;
    }

    @include responsive($tabletSmall){
      position: sticky;
      top: 50%; 
      transform: translate(0, -50%);
      margin: get-vw(100) 0;
    }
  }

  .swiper{
    @include responsive($tabletSmall){
      @include flex();
      flex-wrap: wrap;
      width: 100%;
      margin: get-vw(150) 0;
    }

    &-wrapper{
      @include responsive($tabletSmall){
        flex-wrap: wrap;
        transform: none !important;
        cursor: initial;
      }
    }

    &-navigation{
      @include responsive($tabletSmall, max){
        margin-bottom: 2em;
        justify-content: center;
      }
    }

    &-slide{
      @include responsive($tabletSmall, max){
        max-width: 80%;
      }

      @include responsive($tabletSmall){
        width: 100%;
        max-width: space(7);
      }

      &:nth-child(4n + 1){
        @include responsive($tabletSmall){
          margin-top: get-vw(100);
        }
      }
      
      &:nth-child(4n + 2){
        @include responsive($tabletSmall){
          margin-top: get-vw(50);
          margin-left: auto;
          margin-right: space(1);
        }
      }

      &:nth-child(4n + 3){
        @include responsive($tabletSmall){
          margin-top: get-vw(150);
          margin-left: space(5);
        }
      }

      &:nth-child(4n + 4){
        @include responsive($tabletSmall){
          margin-top: get-vw(250);
          margin-left: auto;
        }
      }

      // Link : see all realisations
      &:last-child{
        @include responsive($tabletSmall){
          margin-top: get-vw(200);
          margin-left: space(2);
        }
      }
    }
  }
}