.TeamStrength{
  position: relative;

  .team{
    height: 500vh;
    
    .sticky{
      position: sticky;
      height: 100vh;
      top: 0;
    }
    
    .head{
      position: absolute;
      top: 50%; left: 0;
      transform: translate(0, -50%);
      
      .title-scroll{
        border-top: 1px solid rgba(0, 0, 0, 0.2);;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        padding: 0.45em 0;
      }
      
      .step{
        position: absolute;
      }
    }
    
    .members{
      @include flex(center);
      height: 100%;

      .inner{
        @include flex(flex-start, space-around);
        flex-wrap: nowrap;

        > .member{
          @include flex(flex-start);
          // margin-right: 15%;
  
          // Each odd member div needs to be at the bottom
          &:nth-of-type(odd){
            margin-top: 58vh;
          }
  
          &:nth-of-type(even){
            .step{
              margin-top: auto;
              margin-bottom: 2.5em;
            }
          }
  
          &:last-child{
            margin-right: 0;
          }
  
          // Portrait of the member
          .visual__container{
            @include imgRatio(280, 280);
            width: get-vw(280);
            border-radius: 50%;

            @include responsive($tabletSmall, max){
              min-width: 120px;
            }
          }
  
          // Member's name
          .step{
            margin: 2.5em 8em 0;
          }
        }
  
        // Member's name
        > .step{
          margin-top: 5%;
          // margin-right: 5%;
          // margin-left: 5%;
          
          @include responsive($tabletSmall){
            margin-top: 13.5em;
            // margin-right: 15%;
            // margin-left: 10%;
          }
        }
      }

      .step{
        white-space: nowrap;
      }
    }
  }

  // Content
  .container.--content{
    @include responsive($tabletSmall, max){
      margin-top: 3em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(50);
    }

    .wrapper{
      @include flex(flex-start);
      
      @include responsive($tabletSmall, max){
        flex-direction: column;
      }
      
      @include responsive($tabletSmall){
        padding: 0 space(2);
      }
    }

    h2{
      // @include responsive($tabletSmall){
      //   display: none;
      // }
    }

    .wswyg--content,
    .Btn{
      @include responsive($tabletSmall, max){
        margin-top: 2em;
      }
    }

    .wswyg--content{
      @include responsive($tabletSmall){
        max-width: space(7);
      }

      *{
        @include responsive($tabletSmall, max){
          text-align: center;
        }
      }
    }

    .Btn{
      @include responsive($tabletSmall, max){
        margin-left: auto;
        margin-right: auto;
      }

      @include responsive($tabletSmall){
        margin-left: auto;
      }
    }
  }
}