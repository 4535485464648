.Agencies{
  display: grid;
  grid-gap: 3em;

  @include responsive($mobile){
    grid-template-columns: repeat(2, 1fr);
  }

  @include responsive($tabletSmall){
    grid-template-columns: repeat(4, 1fr);
    grid-gap: get-vw(30);
  }

  .Nav__container &{
    margin-top: auto;
  }

  .Contact &{
    padding: 0 space(1);

    @include responsive($tabletSmall, max){
      margin-top: 2em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(70);
    }
  }

  .PageHeaderContact &{
    @include responsive($tabletSmall, max){
      margin: 5em 0 3em;
    }

    @include responsive($tabletSmall){
      margin: get-vw(120) space(1) 0;
    }
  }
}

.Agency{
  &:not(.--white){
    color: var(---black);

    > hr{
      border-color: var(--black);
    }

    .Icon__container{
      background: var(--black);

      svg > *{ fill: var(--white) }
    }
  }

  &.--white{
    color: var(--white);

    > hr{
      border-color: var(--white);
    }

    > div{
      .Icon__container{
        background: var(--white);

        svg > *{ fill: var(--black) }
      }
    }
  }

  > hr{
    border-top: 1px solid;
    margin-bottom: 1.5em;
  }

  > div{
    @include flex(flex-start);
    flex-wrap: wrap;

    .Icon__container{
      @include flex(center, center);
      min-height: 50px;
      min-width: 50px;
      height: get-vw(50);
      width: get-vw(50);
      border-radius: 50%;
    }

    .wrapper{
      @include flex();
      flex-direction: column;
      flex: 1;
      margin-left: 20px;
      padding-top: 0.05em;
      text-align: left;

      > *{
        &:not(:first-child){
          font: 400 14px var(--main-font-text);

          @include responsive($tabletSmall){
            @include font-vw(14);
          }
        }

        &:not(:last-child){
          margin-bottom: 0.5em;
        }
      }

      > span{
        font: 400 20px var(--main-font-text);

        @include responsive($tabletSmall){
          @include font-vw(20);
        }
      }
    }
  }
}
