.PageHeader{
  text-align: center;

  @media (max-height: 650px) {
    padding-top: 15em;
  }

  @media (min-height: 650px) {
    padding-top: 35vh;
  }

  .container{
    @include responsive($tabletSmall, max){
      padding-bottom: 3em;
    }

    @include responsive($tabletSmall){
      padding-bottom: get-vw(120);
    }

    .NotFound &{
      @include flex(center, center);
      flex-direction: column;
      gap: 2em; 

      @include responsive($tabletSmall){
        gap: get-vw(30);
      }
    }
  }

  .visual__container{
    @include imgRatio(2880, 1280, var(--black));
  }
}