.Origins{
  .wrapper{
    @include responsive($tabletSmall){
      max-width: space(11);
      margin-left: auto;
    }
  }

  .wswyg--content {
    margin-top: 1em;

    *{
      font: 400 20px var(--main-font-text);
      line-height: 1.15;

      @include responsive($tabletSmall){
        @include font-vw(30);
      }
    }
  }
}