.About{
  > .container{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }
  }

  .wrapper{
    @include responsive($tabletSmall, max){
      margin-bottom: 2em;
    }

    @include responsive($tabletSmall){
      max-width: space(9);
    }
    
    .subtitle{
      margin-bottom: 1.2em;
    }

    .wswyg--content{
      *{
        @include responsive($tabletSmall, max){
          font-size: 20px;
        }

        @include responsive($tabletSmall){
          @include font-vw(35);
        }
      }
    }
  }

  .v-container{
    @include responsive($tabletSmall){
      max-width: space(8);
      margin-left: auto;
    }

    .visual__container{
      @include imgRatio(480, 300, var(--black));
      
      @include responsive($tabletSmall, max){
        margin-bottom: 1.3em;
      }

      @include responsive($tabletSmall){
        margin-bottom: get-vw(40);
      }
    }

    > span{
      color: var(--text-color);
    }
  }
}