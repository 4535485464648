/* -----
Misc
----- */

.is--hidden {
	display: none !important;
}

.is--centered {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.is--left {
	margin-right: auto;
	text-align: left;
}

.is--right{
	margin-left: auto;
	text-align: right;
}

.vh {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    left: -9999px;
    top: -9999px;
}

.no--br br {
  content: '';
  width: 9px;
  height: 18px;
  &:before {
    content: ' '
  }
}

/* -----
Lazyload
----- */

html:not(.no-js) .lazyload,
html:not(.no-js) .lazyloading {
  opacity: 0;
}

html.no-js img.sr-only {
  display:block;
  top:0; left:0;
  width:100%;
  height:100%;
  clip:inherit;
  object-fit:cover;
}

.lazyloaded {
  position: relative;
  opacity: 1;
  transition: opacity 400ms linear 400ms;
}

/* -----
Prllx
----- */

*[data-prllxfrom] {
  will-change:transform;

	&[data-mobile-fix] {
		@include responsive($tabletSmall, max) {
			transform: none!important;
		}
	}
}

/* -----
Sticky
----- */

*[data-sticky-container] {
  &.is--sticky *[data-sticky] {
    position: fixed;
    top: 0;

    .is--animating & {
      position: absolute!important;
      top: 0!important;
    }
  }

  &.is--sticky.is--btm *[data-sticky] {
    position: absolute;
    top: inherit!important;
    bottom: 0;
  }
}

.--disable{ pointer-events: none !important}
.--pr{ position: relative }
.--oh{ overflow: hidden }

.--no-sb{
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
  }
}

// Colors
.--c-black{ color: var(--black) }
.--c-white{ color: var(--white) }

// Backgrounds
.--bg-black{ background: var(--black) }
.--bg-dark-l{ background: var(--dark-l) }
.--bg-grey-l{ background: var(--grey-l) }
.--bg-grey-l-d1{ background: var(--grey-l-d1) }

.--no-sb{
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
  }
}